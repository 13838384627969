
import React from "react";
import styled from "styled-components";
import Colors from "../constants/colors";

const Copyright = styled.footer`
  font-size: 0.8125rem;
  padding: 20px;
  text-align: center;
  color: ${Colors.Text.copyright};
  background-color: ${Colors.Background.footer};
`;

const Footer = () => {
  return (
    <Copyright>
      © {new Date().getFullYear()} by Hung-Yu Yang (Hermit Yang).
      {` `}
      All rights reserved.
    </Copyright>
  );
}

export default Footer;