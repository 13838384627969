import React from "react";
import styled from "styled-components";

import Breakpoints from "../constants/breakpoints";
import logo from "../images/logo/logo-40x48@2x.png";
import logo_small from "../images/logo/logo-32x38@2x.png";

const LogoContainer = styled.a`
  display: block;
  width: ${props => props.small ? "32px" : "40px"};
  height: ${props => props.small ? "38px" : "48px"};
  margin-left: 32px;
  margin-right: ${props => props.small ? "20px" : "32px"};
  align-self: center;
  opacity: 1;
  transition-property: all;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  
  &.hidden {
    opacity: 0;
  }

  @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
    margin-left: 72px;
  }
`;

const Logo = styled.img`
  width: 40px;
  min-width: 40px;
  height: 48px;
`;

const LogoSmall = styled.img`
  width: 32px;
  min-width: 32px;
  height: 38px;
`;

const SiteLogo = props => {
  return (
    <LogoContainer className={props.className} small={props.small} href="/">
      {props.small 
        ? <LogoSmall src={logo_small} alt='Hermit Yang logo'/>
        : <Logo src={logo} alt='Hermit Yang logo'/>
      }
        
    </LogoContainer>
  );
};

export default SiteLogo; 

